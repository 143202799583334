<template>
  <!-- Main Sidebar Container -->
  <aside class="main-sidebar elevation-4" style="background-color: #fff">
    <!-- Brand Logo -->
    <div class="brand-link d-flex">
      <img
        src="../assets/LogoBNI.png"
        style="
          width: 40px;
          height: 15px;
          margin-top: 7px;
          margin-right: 5px;
          margin-left: 5px;
        "
        alt=""
      />
      <span
        class="brand-text white-all"
        style="
          text-transform: uppercase;
          background: linear-gradient(to right, #ff3300 10%, #006699 100%);
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
          font: {
            size: 20vw;
            family: $font;
          }
          margin-left: 3.5%;
        "
        >E-BUDGETING</span
      >
    </div>

    <!-- Sidebar -->
    <div class="sidebar">
      <!-- Sidebar user panel (optional) -->
      <div class="user-panel mt-3 mb-3 d-flex pt-1">
        <div class="image">
          <!-- <img src="../assets/img/avatar5.png" class="img-circle elevation-2" alt="User Image"> -->
          <!-- <svg
            xmlns="http://www.w3.org/2000/svg"
            height="32"
            viewBox="0 -960 960 960"
            width="32"
            class="img-circle elevation-2" 
          >
            <path
              d="M480-480q-66 0-113-47t-47-113q0-66 47-113t113-47q66 0 113 47t47 113q0 66-47 113t-113 47ZM160-160v-112q0-34 17.5-62.5T224-378q62-31 126-46.5T480-440q66 0 130 15.5T736-378q29 15 46.5 43.5T800-272v112H160Zm80-80h480v-32q0-11-5.5-20T700-306q-54-27-109-40.5T480-360q-56 0-111 13.5T260-306q-9 5-14.5 14t-5.5 20v32Zm240-320q33 0 56.5-23.5T560-640q0-33-23.5-56.5T480-720q-33 0-56.5 23.5T400-640q0 33 23.5 56.5T480-560Zm0-80Zm0 400Z"
            />
          </svg> -->
        </div>
        <div class="info border-b-2 border-gray-500">
          <label class="d-block white-all m-0">{{ userSession.nama }}</label>
          <p class="text-sm m-0" style="color: #1e1e1e">{{ namaAkses }}</p>
          <p
            class="text-sm m-0"
            style="color: #1e1e1e"
            v-show="
              namaAkses == 'Officer' ||
              namaAkses == 'BUM' ||
              namaAkses == 'Departemen Head'
            "
          >
            {{ userSession.nama_departement }}
          </p>
          <p class="text-sm m-0" style="color: #1e1e1e">
            Tanggal : {{ currentDate }}
          </p>
        </div>
      </div>

      <!-- Sidebar Menu -->
      <nav class="mt-2">
        <ul
          class="nav nav-pills nav-sidebar flex-column"
          data-widget="treeview"
          role="menu"
          data-accordion="false"
        >
          <!-- Add icons to the links using the .nav-icon class
               with font-awesome or any other icon font library -->
          <li
            class="nav-item nav-style"
            v-show="namaAkses == 'General Manager'"
          >
            <router-link
              to="/dashboard"
              :class="NamePage == 'Dashboard' ? Active : notActive"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                height="24"
                viewBox="0 -960 960 960"
                width="24"
                class="nav-icon"
              >
                <path
                  d="M530.001-600v-219.999h289.998V-600H530.001Zm-390 139.999v-359.998h289.998v359.998H140.001Zm390 320v-359.998h289.998v359.998H530.001Zm-390 0V-360h289.998v219.999H140.001ZM200-520h170.001v-240H200v240Zm389.999 320H760v-240H589.999v240Zm0-459.999H760V-760H589.999v100.001ZM200-200h170.001v-100.001H200V-200Zm170.001-320Zm219.998-139.999Zm0 219.999ZM370.001-300.001Z"
                />
              </svg>
              <p style="color: #1e1e1e">Dashboard</p>
            </router-link>
          </li>

          <!-- Admin Sistem -->
          <li class="nav-item" v-show="namaAkses == 'Admin Sistem'">
            <a href="#" class="nav-link">
              <p class="white-all">
                ADMINISTRASI
                <i class="right fas fa-angle-left"></i>
              </p>
            </a>
            <ul class="nav nav-treeview">
              <li class="nav-item nav-style">
                <router-link
                  to="/user"
                  :class="NamePage == 'Daftar User' ? Active : notActive"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    height="24"
                    viewBox="0 -960 960 960"
                    width="24"
                    class="nav-icon"
                  >
                    <path
                      d="M400-492.309q-57.749 0-98.874-41.124-41.125-41.125-41.125-98.874 0-57.75 41.125-98.874 41.125-41.125 98.874-41.125 57.749 0 98.874 41.125 41.125 41.124 41.125 98.874 0 57.749-41.125 98.874-41.125 41.124-98.874 41.124ZM100.001-187.694v-88.922q0-30.307 15.462-54.884 15.461-24.576 43.153-38.038 49.847-24.846 107.692-41.5Q324.154-427.691 400-427.691h11.692q4.846 0 10.462 1.23-6.077 14.154-10.039 28.846-3.961 14.692-6.576 29.922H400q-69.077 0-122.307 15.885-53.231 15.884-91.539 35.807-13.615 7.308-19.885 17.077-6.269 9.77-6.269 22.308v28.923h252q4.461 15.23 11.577 30.922 7.115 15.692 15.653 29.077H100.001Zm544.23 29.614-8.923-53.076q-14.308-4.231-26.923-11.077-12.616-6.846-24-17.154l-50.692 17.615-28.461-48.383 41.384-33.846q-4.307-15.538-4.307-30.615 0-15.078 4.307-30.616l-40.999-34.615 28.461-48.383 50.307 18q11-10.308 23.808-16.962 12.807-6.654 27.115-10.885l8.923-53.076h56.921l8.539 53.076q14.308 4.231 27.115 11.193 12.808 6.961 23.808 17.884l50.307-19.23 28.461 49.614-41 34.615q4.308 14.429 4.308 30.061 0 15.631-4.308 29.939l41.385 33.846-28.461 48.383-50.692-17.615q-11.384 10.308-24 17.154-12.615 6.846-26.923 11.077l-8.539 53.076h-56.921Zm28.11-100.383q31.428 0 53.774-22.38t22.346-53.807q0-31.428-22.38-53.774t-53.808-22.346q-31.427 0-53.773 22.38-22.346 22.38-22.346 53.808 0 31.427 22.38 53.773 22.38 22.346 53.807 22.346ZM400-552.307q33 0 56.5-23.5t23.5-56.5q0-33-23.5-56.5t-56.5-23.5q-33 0-56.5 23.5t-23.5 56.5q0 33 23.5 56.5t56.5 23.5Zm0-80Zm12 384.614Z"
                    />
                  </svg>
                  <p style="color: #1e1e1e">Pengguna</p>
                </router-link>
              </li>
              <li class="nav-item nav-style">
                <router-link
                  to="/entitas"
                  :class="NamePage == 'Daftar Entitas' ? Active : notActive"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    height="24"
                    viewBox="0 -960 960 960"
                    width="24"
                    class="nav-icon"
                  >
                    <path
                      d="M292.309-60.002q-29.827 0-51.067-21.24-21.241-21.24-21.241-51.067v-695.382q0-29.827 21.241-51.067 21.24-21.241 51.067-21.241h375.382q29.827 0 51.067 21.241 21.241 21.24 21.241 51.067v695.382q0 29.827-21.241 51.067-21.24 21.24-51.067 21.24H292.309ZM280-170v37.692q0 5.385 3.462 8.847 3.462 3.462 8.847 3.462h375.382q5.385 0 8.847-3.462 3.462-3.462 3.462-8.847v-37.692H280Zm0-619.998h400v-37.692q0-5.385-3.462-8.847-3.462-3.462-8.847-3.462H292.309q-5.385 0-8.847 3.462-3.462 3.462-3.462 8.847v37.692Zm200 449.998q-54 0-105 15.5t-95 44.5v50.002h400v-50.002q-44-29-95-44.5t-105-15.5ZM480-400q54 0 104.5 13.101t95.5 37.207v-380.309H280v380.309q45-24.106 95.5-37.207Q426-400 480-400Zm-.045-50.001q45.814 0 77.929-32.07t32.115-77.884q0-45.814-32.07-77.929t-77.884-32.115q-45.814 0-77.929 32.07t-32.115 77.884q0 45.814 32.07 77.929t77.884 32.115Zm.045-59.998q-20.846 0-35.424-14.577-14.577-14.578-14.577-35.424t14.577-35.424q14.578-14.577 35.424-14.577t35.424 14.577q14.577 14.578 14.577 35.424t-14.577 35.424Q500.846-509.999 480-509.999Zm0 280h200-400 200ZM480-560Zm0-229.999Zm0 619.998Z"
                    />
                  </svg>
                  <p style="color: #1e1e1e">Entitas</p>
                </router-link>
              </li>
              <li class="nav-item nav-style">
                <router-link
                  to="/departemen"
                  :class="NamePage == 'Daftar Departemen' ? Active : notActive"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    height="24"
                    viewBox="0 -960 960 960"
                    width="24"
                    class="nav-icon"
                  >
                    <path
                      d="M20.002-248.462v-48.768q0-39.923 41.692-65.577 41.692-25.654 108.691-25.654 11.077 0 22.308.693 11.231.692 22.615 2.692-11.692 18.692-17.346 38.807-5.653 20.115-5.653 41.269v56.538H20.001Zm239.999 0v-54.999q0-28.091 15.77-51.353 15.769-23.262 45.461-40.57 29.692-17.307 70.153-25.961 40.461-8.654 88.461-8.654 48.923 0 89.384 8.654t70.154 25.961q29.692 17.308 45.153 40.57 15.462 23.262 15.462 51.353v54.999H260.001Zm507.69 0v-56.423q0-22.569-5.346-42.534t-16.038-37.657q11.769-2 22.5-2.692 10.731-.693 21.192-.693 67 0 108.5 25.346t41.5 65.885v48.768H767.691Zm-444.614-59.999h314.462v-4.231q-6.154-24.231-51.077-40.769Q541.539-370 480-370t-106.462 16.539q-44.923 16.538-50.461 40.769v4.231ZM170.283-427.307q-28.282 0-48.32-20.11t-20.038-48.35q0-28.615 20.11-48.346 20.11-19.731 48.35-19.731 28.615 0 48.538 19.731t19.923 48.46q0 27.885-19.712 48.116-19.712 20.23-48.851 20.23Zm619.716 0q-27.999 0-48.23-20.23-20.23-20.231-20.23-48.116 0-28.729 20.23-48.46 20.231-19.731 48.305-19.731 28.925 0 48.655 19.731 19.731 19.731 19.731 48.346 0 28.24-19.682 48.35-19.683 20.11-48.779 20.11Zm-309.863-32.692q-43.212 0-73.597-30.288-30.384-30.288-30.384-73.557 0-44.134 30.288-73.989 30.288-29.855 73.557-29.855 44.134 0 73.99 29.816 29.855 29.816 29.855 73.893 0 43.212-29.816 73.596-29.817 30.384-73.893 30.384Zm.056-59.998q18.347 0 31.001-12.846 12.653-12.847 12.653-31.193 0-18.346-12.605-31Q498.635-607.69 480-607.69q-18.154 0-31 12.606t-12.846 31.24q0 18.154 12.846 31.001 12.846 12.846 31.192 12.846Zm.423 211.536ZM480-563.844Z"
                    />
                  </svg>
                  <p style="color: #1e1e1e">Departemen</p>
                </router-link>
              </li>
              <li class="nav-item nav-style">
                <router-link
                  to="/kelompokMataAnggaran"
                  :class="
                    NamePage == 'Kelompok Mata Anggaran' ? Active : notActive
                  "
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    height="24"
                    viewBox="0 -960 960 960"
                    width="24"
                    class="nav-icon"
                  >
                    <path
                      d="M212.309-100.001q-29.923 0-51.115-21.193-21.193-21.192-21.193-51.115v-447.922q-17.615-9.077-28.807-25.808-11.193-16.731-11.193-38.577v-103.075q0-29.923 21.193-51.115 21.192-21.193 51.115-21.193h615.382q29.923 0 51.115 21.193 21.193 21.192 21.193 51.115v103.075q0 21.846-11.193 38.577-11.192 16.731-28.807 25.808v447.922q0 29.923-21.193 51.115-21.192 21.193-51.115 21.193H212.309ZM200-612.309v438.077q0 6.154 4.423 10.193Q208.847-160 215.386-160h532.305q5.385 0 8.847-3.462 3.462-3.462 3.462-8.847v-440H200Zm-27.691-59.998h615.382q5.385 0 8.847-3.462 3.462-3.462 3.462-8.847v-103.075q0-5.385-3.462-8.847-3.462-3.462-8.847-3.462H172.309q-5.385 0-8.847 3.462-3.462 3.462-3.462 8.847v103.075q0 5.385 3.462 8.847 3.462 3.462 8.847 3.462Zm195.384 249.613h224.614V-480H367.693v57.306ZM480-386.154Z"
                    />
                  </svg>
                  <p style="color: #1e1e1e">Kelompok Mata Anggaran</p>
                </router-link>
              </li>
              <li class="nav-item">
                <a href="#" class="nav-link">
                  <p class="white-all">
                    MATA ANGGARAN
                    <i class="right fas fa-angle-left"></i>
                  </p>
                </a>
                <ul class="nav nav-treeview">
                  <li class="nav-item nav-style">
                    <router-link
                      to="/mataAnggaran"
                      :class="NamePage == 'Mata Anggaran' ? Active : notActive"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        height="24"
                        viewBox="0 -960 960 960"
                        width="24"
                        class="nav-icon"
                      >
                        <path
                          d="M549.999-451.539q-41.922 0-70.96-29.038-29.038-29.038-29.038-70.961 0-41.922 29.038-70.96 29.038-29.038 70.96-29.038 41.923 0 70.961 29.038 29.038 29.038 29.038 70.96 0 41.923-29.038 70.961-29.038 29.038-70.961 29.038ZM286.154-327.693q-29.826 0-51.067-21.24-21.24-21.24-21.24-51.067v-303.076q0-29.826 21.24-51.067 21.241-21.24 51.067-21.24h527.69q29.827 0 51.067 21.24 21.24 21.241 21.24 51.067V-400q0 29.827-21.24 51.067t-51.067 21.24h-527.69Zm59.999-59.998h407.693q0-29.923 21.24-51.115 21.24-21.193 51.067-21.193v-183.078q-29.923 0-51.115-21.24-21.192-21.241-21.192-51.067H346.153q0 29.923-21.24 51.115-21.241 21.192-51.067 21.192v183.078q29.923 0 51.115 21.241 21.192 21.24 21.192 51.067Zm420.768 199.997H146.156q-29.827 0-51.067-21.241-21.24-21.24-21.24-51.066v-396.151h59.998v396.151q0 4.615 3.847 8.462 3.846 3.846 8.462 3.846h620.765v59.999ZM286.154-387.691h-12.308V-715.384h12.308q-5 0-8.654 3.654t-3.654 8.654V-400q0 5 3.654 8.654 3.654 3.655 8.654 3.655Z"
                        />
                      </svg>
                      <p style="color: #1e1e1e">Mata Anggaran</p>
                    </router-link>
                  </li>
                  <li class="nav-item nav-style">
                    <router-link
                      to="/submataAnggaran"
                      :class="
                        NamePage == 'Sub Mata Anggaran' ? Active : notActive
                      "
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        height="24"
                        viewBox="0 -960 960 960"
                        width="24"
                        class="nav-icon"
                      >
                        <path
                          d="M549.999-451.539q-41.922 0-70.96-29.038-29.038-29.038-29.038-70.961 0-41.922 29.038-70.96 29.038-29.038 70.96-29.038 41.923 0 70.961 29.038 29.038 29.038 29.038 70.96 0 41.923-29.038 70.961-29.038 29.038-70.961 29.038ZM286.154-327.693q-29.826 0-51.067-21.24-21.24-21.24-21.24-51.067v-303.076q0-29.826 21.24-51.067 21.241-21.24 51.067-21.24h527.69q29.827 0 51.067 21.24 21.24 21.241 21.24 51.067V-400q0 29.827-21.24 51.067t-51.067 21.24h-527.69Zm59.999-59.998h407.693q0-29.923 21.24-51.115 21.24-21.193 51.067-21.193v-183.078q-29.923 0-51.115-21.24-21.192-21.241-21.192-51.067H346.153q0 29.923-21.24 51.115-21.241 21.192-51.067 21.192v183.078q29.923 0 51.115 21.241 21.192 21.24 21.192 51.067Zm420.768 199.997H146.156q-29.827 0-51.067-21.241-21.24-21.24-21.24-51.066v-396.151h59.998v396.151q0 4.615 3.847 8.462 3.846 3.846 8.462 3.846h620.765v59.999ZM286.154-387.691h-12.308V-715.384h12.308q-5 0-8.654 3.654t-3.654 8.654V-400q0 5 3.654 8.654 3.654 3.655 8.654 3.655Z"
                        />
                      </svg>
                      <p style="color: #1e1e1e">Sub Mata Anggaran</p>
                    </router-link>
                  </li>
                </ul>
              </li>
            </ul>
          </li>

          <!-- Superadmin -->
          <li class="nav-item" v-show="namaAkses == 'Superadmin'">
            <a href="#" class="nav-link">
              <p class="white-all">
                TRANSAKSI
                <i class="right fas fa-angle-left"></i>
              </p>
            </a>
            <ul class="nav nav-treeview">
              <li class="nav-item nav-style">
                <router-link
                  to="/anggaran"
                  :class="NamePage == 'Daftar Anggaran' ? Active : notActive"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    height="24"
                    viewBox="0 -960 960 960"
                    width="24"
                    class="nav-icon"
                  >
                    <path
                      d="M549.999-451.539q-41.922 0-70.96-29.038-29.038-29.038-29.038-70.961 0-41.922 29.038-70.96 29.038-29.038 70.96-29.038 41.923 0 70.961 29.038 29.038 29.038 29.038 70.96 0 41.923-29.038 70.961-29.038 29.038-70.961 29.038ZM286.154-327.693q-29.826 0-51.067-21.24-21.24-21.24-21.24-51.067v-303.076q0-29.826 21.24-51.067 21.241-21.24 51.067-21.24h527.69q29.827 0 51.067 21.24 21.24 21.241 21.24 51.067V-400q0 29.827-21.24 51.067t-51.067 21.24h-527.69Zm59.999-59.998h407.693q0-29.923 21.24-51.115 21.24-21.193 51.067-21.193v-183.078q-29.923 0-51.115-21.24-21.192-21.241-21.192-51.067H346.153q0 29.923-21.24 51.115-21.241 21.192-51.067 21.192v183.078q29.923 0 51.115 21.241 21.192 21.24 21.192 51.067Zm420.768 199.997H146.156q-29.827 0-51.067-21.241-21.24-21.24-21.24-51.066v-396.151h59.998v396.151q0 4.615 3.847 8.462 3.846 3.846 8.462 3.846h620.765v59.999ZM286.154-387.691h-12.308V-715.384h12.308q-5 0-8.654 3.654t-3.654 8.654V-400q0 5 3.654 8.654 3.654 3.655 8.654 3.655Z"
                    />
                  </svg>
                  <p style="color: #1e1e1e">Anggaran</p>
                </router-link>
              </li>
              <li class="nav-item nav-style">
                <router-link
                  to="/topupanggaran"
                  :class="NamePage == 'Top Up Anggaran' ? Active : notActive"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    height="24"
                    viewBox="0 -960 960 960"
                    width="24"
                    class="nav-icon"
                  >
                    <path
                      d="M549.999-451.539q-41.922 0-70.96-29.038-29.038-29.038-29.038-70.961 0-41.922 29.038-70.96 29.038-29.038 70.96-29.038 41.923 0 70.961 29.038 29.038 29.038 29.038 70.96 0 41.923-29.038 70.961-29.038 29.038-70.961 29.038ZM286.154-327.693q-29.826 0-51.067-21.24-21.24-21.24-21.24-51.067v-303.076q0-29.826 21.24-51.067 21.241-21.24 51.067-21.24h527.69q29.827 0 51.067 21.24 21.24 21.241 21.24 51.067V-400q0 29.827-21.24 51.067t-51.067 21.24h-527.69Zm59.999-59.998h407.693q0-29.923 21.24-51.115 21.24-21.193 51.067-21.193v-183.078q-29.923 0-51.115-21.24-21.192-21.241-21.192-51.067H346.153q0 29.923-21.24 51.115-21.241 21.192-51.067 21.192v183.078q29.923 0 51.115 21.241 21.192 21.24 21.192 51.067Zm420.768 199.997H146.156q-29.827 0-51.067-21.241-21.24-21.24-21.24-51.066v-396.151h59.998v396.151q0 4.615 3.847 8.462 3.846 3.846 8.462 3.846h620.765v59.999ZM286.154-387.691h-12.308V-715.384h12.308q-5 0-8.654 3.654t-3.654 8.654V-400q0 5 3.654 8.654 3.654 3.655 8.654 3.655Z"
                    />
                  </svg>
                  <p style="color: #1e1e1e">Validasi Top Up Anggaran</p>
                </router-link>
              </li>
              <li class="nav-item nav-style">
                <router-link
                  to="/persenAnggaran"
                  :class="
                    NamePage == 'Persentasi Anggaran' ? Active : notActive
                  "
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    height="24"
                    viewBox="0 -960 960 960"
                    width="24"
                    class="nav-icon"
                  >
                    <path
                      d="M306.539-527.308q-52.423 0-89.48-37.058-37.058-37.057-37.058-89.48 0-52.422 37.058-89.48 37.057-37.057 89.48-37.057t89.48 37.057q37.057 37.058 37.057 89.48 0 52.423-37.057 89.48-37.057 37.058-89.48 37.058Zm-.023-59.999q27.715 0 47.139-19.4 19.423-19.401 19.423-47.116t-19.401-47.138q-19.4-19.424-47.115-19.424-27.716 0-47.139 19.401Q240-681.583 240-653.868t19.401 47.138q19.4 19.423 47.115 19.423Zm347.33 407.306q-52.423 0-89.48-37.058-37.058-37.057-37.058-89.48t37.058-89.48q37.057-37.057 89.48-37.057 52.422 0 89.48 37.057 37.057 37.057 37.057 89.48t-37.057 89.48q-37.058 37.058-89.48 37.058ZM653.823-240q27.715 0 47.138-19.401 19.424-19.4 19.424-47.115 0-27.715-19.401-47.139-19.401-19.423-47.116-19.423t-47.138 19.401q-19.423 19.4-19.423 47.115 0 27.716 19.4 47.139Q626.108-240 653.823-240Zm-428.207 56.152-41.768-41.768 550.921-550.921 41.768 41.768-550.921 550.921Z"
                    />
                  </svg>
                  <p style="color: #1e1e1e">Persentasi Anggaran</p>
                </router-link>
              </li>
              <li class="nav-item nav-style">
                <router-link
                  to="/switchDepartemen"
                  :class="
                    NamePage == 'Switch Anggaran Antar Departemen'
                      ? Active
                      : notActive
                  "
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    height="24"
                    viewBox="0 -960 960 960"
                    width="24"
                    class="nav-icon"
                  >
                    <path
                      d="M320-160q-117 0-198.5-81.5T40-440q0-107 70.5-186.5T287-718l-63-66 56-56 160 160-160 160-56-57 59-59q-71 14-117 69t-46 127q0 83 58.5 141.5T320-240h120v80H320Zm200-360v-280h360v280H520Zm0 360v-280h360v280H520Zm80-80h200v-120H600v120Z"
                    />
                  </svg>
                  <p style="color: #1e1e1e">
                    History Switch Anggaran (Antar Departemen)
                  </p>
                </router-link>
              </li>
            </ul>
          </li>

          <!-- Officer -->
          <li class="nav-item" v-show="namaAkses == 'Officer'">
            <a href="#" class="nav-link">
              <p class="white-all">
                TRANSAKSI
                <i class="right fas fa-angle-left"></i>
              </p>
            </a>
            <ul class="nav nav-treeview">
              <li class="nav-item nav-style">
                <router-link
                  to="/anggaran"
                  :class="NamePage == 'Daftar Anggaran' ? Active : notActive"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    height="24"
                    viewBox="0 -960 960 960"
                    width="24"
                    class="nav-icon"
                  >
                    <path
                      d="M549.999-451.539q-41.922 0-70.96-29.038-29.038-29.038-29.038-70.961 0-41.922 29.038-70.96 29.038-29.038 70.96-29.038 41.923 0 70.961 29.038 29.038 29.038 29.038 70.96 0 41.923-29.038 70.961-29.038 29.038-70.961 29.038ZM286.154-327.693q-29.826 0-51.067-21.24-21.24-21.24-21.24-51.067v-303.076q0-29.826 21.24-51.067 21.241-21.24 51.067-21.24h527.69q29.827 0 51.067 21.24 21.24 21.241 21.24 51.067V-400q0 29.827-21.24 51.067t-51.067 21.24h-527.69Zm59.999-59.998h407.693q0-29.923 21.24-51.115 21.24-21.193 51.067-21.193v-183.078q-29.923 0-51.115-21.24-21.192-21.241-21.192-51.067H346.153q0 29.923-21.24 51.115-21.241 21.192-51.067 21.192v183.078q29.923 0 51.115 21.241 21.192 21.24 21.192 51.067Zm420.768 199.997H146.156q-29.827 0-51.067-21.241-21.24-21.24-21.24-51.066v-396.151h59.998v396.151q0 4.615 3.847 8.462 3.846 3.846 8.462 3.846h620.765v59.999ZM286.154-387.691h-12.308V-715.384h12.308q-5 0-8.654 3.654t-3.654 8.654V-400q0 5 3.654 8.654 3.654 3.655 8.654 3.655Z"
                    />
                  </svg>
                  <p style="color: #1e1e1e">Anggaran</p>
                </router-link>
              </li>
              <li class="nav-item nav-style">
                <router-link
                  to="/kegiatan"
                  :class="
                    NamePage == 'Daftar Proyeksi Kegiatan' ? Active : notActive
                  "
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    height="24"
                    viewBox="0 -960 960 960"
                    width="24"
                    class="nav-icon"
                  >
                    <path
                      d="M587.693-240q-38.538 0-65.423-26.884-26.884-26.885-26.884-65.423t26.884-65.423q26.885-26.884 65.423-26.884t65.423 26.884Q680-370.845 680-332.307t-26.884 65.423Q626.231-240 587.693-240ZM212.309-100.001q-30.308 0-51.308-21t-21-51.308v-535.382q0-30.308 21-51.308t51.308-21h55.385v-84.615h61.537v84.615h303.076v-84.615h59.999v84.615h55.385q30.308 0 51.308 21t21 51.308v535.382q0 30.308-21 51.308t-51.308 21H212.309Zm0-59.999h535.382q4.616 0 8.463-3.846 3.846-3.847 3.846-8.463v-375.382H200v375.382q0 4.616 3.846 8.463 3.847 3.846 8.463 3.846ZM200-607.69h560v-100.001q0-4.616-3.846-8.463-3.847-3.846-8.463-3.846H212.309q-4.616 0-8.463 3.846-3.846 3.847-3.846 8.463v100.001Zm0 0V-720v112.31Z"
                    />
                  </svg>
                  <p style="color: #1e1e1e">Proyeksi Kegiatan</p>
                </router-link>
              </li>
            </ul>
          </li>
          <!-- pengajuan -->
          <li class="nav-item" v-show="namaAkses == 'Officer'">
            <a href="#" class="nav-link">
              <p class="white-all">
                PENGAJUAN
                <i class="right fas fa-angle-left"></i>
              </p>
            </a>
            <ul class="nav nav-treeview">
              <li class="nav-item nav-style">
                <router-link
                  to="/pengajuanBiaya"
                  :class="NamePage == 'Pengajuan Biaya' ? Active : notActive"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    height="24"
                    viewBox="0 -960 960 960"
                    width="24"
                    class="nav-icon"
                  >
                    <path
                      d="M200-607.69h560v-100.001q0-4.616-3.846-8.463-3.847-3.846-8.463-3.846H212.309q-4.616 0-8.463 3.846-3.846 3.847-3.846 8.463v100.001Zm0 0V-720v112.31Zm12.309 507.689q-30.308 0-51.308-21t-21-51.308v-535.382q0-30.308 21-51.308t51.308-21h55.385v-84.615h61.537v84.615h303.076v-84.615h59.999v84.615h55.385q30.308 0 51.308 21t21 51.308v218.537q-14.385-6.307-29.384-10.192-15-3.884-30.615-6.115v-42.23H200v375.382q0 4.616 3.846 8.463 3.847 3.846 8.463 3.846h260.077q5.077 16.615 12.269 31.422 7.192 14.808 16.346 28.577H212.309Zm515.384 40q-74.923 0-127.461-52.538Q547.694-165.078 547.694-240t52.538-127.461q52.538-52.538 127.461-52.538 74.922 0 127.46 52.538Q907.691-314.922 907.691-240t-52.538 127.461q-52.538 52.538-127.46 52.538Zm66.615-88.461 24.923-24.923-73.846-73.846v-110.461h-35.384v124.923l84.307 84.307Z"
                    />
                  </svg>
                  <p style="color: #1e1e1e">Pengajuan Biaya</p>
                </router-link>
              </li>
              <li class="nav-item nav-style">
                <router-link
                  to="/pengajuanRetur"
                  :class="NamePage == 'Pengajuan Retur' ? Active : notActive"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    height="24"
                    viewBox="0 -960 960 960"
                    width="24"
                    class="nav-icon"
                  >
                    <path
                      d="M480-333.847 522.153-376l-73.386-74.001h177.386v-59.998H448.767L522.153-584 480-626.153 333.847-480 480-333.847ZM212.309-140.001q-29.923 0-51.115-21.193-21.193-21.192-21.193-51.115v-535.382q0-29.923 21.193-51.115 21.192-21.193 51.115-21.193h178q3.769-33.307 29.076-56.653 25.308-23.347 60.615-23.347 35.692 0 60.999 23.347 25.308 23.346 28.692 56.653h178q29.923 0 51.115 21.193 21.193 21.192 21.193 51.115v535.382q0 29.923-21.193 51.115-21.192 21.193-51.115 21.193H212.309Zm0-59.999h535.382q4.616 0 8.463-3.846 3.846-3.847 3.846-8.463v-535.382q0-4.616-3.846-8.463-3.847-3.846-8.463-3.846H212.309q-4.616 0-8.463 3.846-3.846 3.847-3.846 8.463v535.382q0 4.616 3.846 8.463 3.847 3.846 8.463 3.846ZM480-786.154q13 0 21.5-8.5t8.5-21.5q0-13-8.5-21.5t-21.5-8.5q-13 0-21.5 8.5t-8.5 21.5q0 13 8.5 21.5t21.5 8.5ZM200-200V-760-200Z"
                    />
                  </svg>
                  <p style="color: #1e1e1e">Pengajuan Retur</p>
                </router-link>
              </li>
              <li class="nav-item nav-style">
                <router-link
                  to="/pengajuanKomitmen"
                  :class="NamePage == 'Pengajuan Termin' ? Active : notActive"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    height="24"
                    viewBox="0 -960 960 960"
                    width="24"
                    class="nav-icon"
                  >
                    <path
                      d="m618.924-298.924 42.152-42.152-151.077-151.087V-680h-59.998v212.154l168.923 168.922ZM480.067-100.001q-78.836 0-148.204-29.92-69.369-29.92-120.682-81.21-51.314-51.291-81.247-120.629-29.933-69.337-29.933-148.173t29.92-148.204q29.92-69.369 81.21-120.682 51.291-51.314 120.629-81.247 69.337-29.933 148.173-29.933t148.204 29.92q69.369 29.92 120.682 81.21 51.314 51.291 81.247 120.629 29.933 69.337 29.933 148.173t-29.92 148.204q-29.92 69.369-81.21 120.682-51.291 51.314-120.629 81.247-69.337 29.933-148.173 29.933ZM480-480Zm0 320q133 0 226.5-93.5T800-480q0-133-93.5-226.5T480-800q-133 0-226.5 93.5T160-480q0 133 93.5 226.5T480-160Z"
                    />
                  </svg>
                  <p style="color: #1e1e1e">Pengajuan Termin Pembayaran</p>
                </router-link>
              </li>
              <li class="nav-item nav-style">
                <router-link
                  to="/realisasianggaran"
                  :class="NamePage == 'Realisasi Anggaran' ? Active : notActive"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    height="24"
                    viewBox="0 -960 960 960"
                    width="24"
                    class="nav-icon"
                  >
                    <path
                      d="M549.999-451.539q-41.922 0-70.96-29.038-29.038-29.038-29.038-70.961 0-41.922 29.038-70.96 29.038-29.038 70.96-29.038 41.923 0 70.961 29.038 29.038 29.038 29.038 70.96 0 41.923-29.038 70.961-29.038 29.038-70.961 29.038ZM286.154-327.693q-29.826 0-51.067-21.24-21.24-21.24-21.24-51.067v-303.076q0-29.826 21.24-51.067 21.241-21.24 51.067-21.24h527.69q29.827 0 51.067 21.24 21.24 21.241 21.24 51.067V-400q0 29.827-21.24 51.067t-51.067 21.24h-527.69Zm59.999-59.998h407.693q0-29.923 21.24-51.115 21.24-21.193 51.067-21.193v-183.078q-29.923 0-51.115-21.24-21.192-21.241-21.192-51.067H346.153q0 29.923-21.24 51.115-21.241 21.192-51.067 21.192v183.078q29.923 0 51.115 21.241 21.192 21.24 21.192 51.067Zm420.768 199.997H146.156q-29.827 0-51.067-21.241-21.24-21.24-21.24-51.066v-396.151h59.998v396.151q0 4.615 3.847 8.462 3.846 3.846 8.462 3.846h620.765v59.999ZM286.154-387.691h-12.308V-715.384h12.308q-5 0-8.654 3.654t-3.654 8.654V-400q0 5 3.654 8.654 3.654 3.655 8.654 3.655Z"
                    />
                  </svg>
                  <p style="color: #1e1e1e">Pengajuan Realisasi Anggaran</p>
                </router-link>
              </li>
            </ul>
          </li>
           <!-- switch anggaran -->

          <li class="nav-item" v-show="namaAkses == 'Officer'">
            <a href="#" class="nav-link">
              <p class="white-all">
                SWITCH ANGGARAN
                <i class="right fas fa-angle-left"></i>
              </p>
            </a>
            <ul class="nav nav-treeview">
              <li class="nav-item nav-style">
                <router-link
                  to="/switchSubMataAnggaran"
                  :class="
                    NamePage == 'Switch Sub Mata Anggaran' ? Active : notActive
                  "
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    height="24"
                    viewBox="0 -960 960 960"
                    width="24"
                    class="nav-icon"
                  >
                    <path
                      d="M320-160q-117 0-198.5-81.5T40-440q0-107 70.5-186.5T287-718l-63-66 56-56 160 160-160 160-56-57 59-59q-71 14-117 69t-46 127q0 83 58.5 141.5T320-240h120v80H320Zm200-360v-280h360v280H520Zm0 360v-280h360v280H520Zm80-80h200v-120H600v120Z"
                    />
                  </svg>
                  <p style="color: #1e1e1e">History (Sub Mata Anggaran)</p>
                </router-link>
              </li>
              <li class="nav-item nav-style">
                <router-link
                  to="/switchDepartemen"
                  :class="
                    NamePage == 'Switch Anggaran Antar Departemen'
                      ? Active
                      : notActive
                  "
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    height="24"
                    viewBox="0 -960 960 960"
                    width="24"
                    class="nav-icon"
                  >
                    <path
                      d="M320-160q-117 0-198.5-81.5T40-440q0-107 70.5-186.5T287-718l-63-66 56-56 160 160-160 160-56-57 59-59q-71 14-117 69t-46 127q0 83 58.5 141.5T320-240h120v80H320Zm200-360v-280h360v280H520Zm0 360v-280h360v280H520Zm80-80h200v-120H600v120Z"
                    />
                  </svg>
                  <p style="color: #1e1e1e">History (Antar Departemen)</p>
                </router-link>
              </li>
            </ul>
          </li>

          <!-- Departemen Head -->
          <li class="nav-item" v-show="namaAkses == 'Departemen Head'">
            <a href="#" class="nav-link">
              <p class="white-all">
                TRANSAKSI
                <i class="right fas fa-angle-left"></i>
              </p>
            </a>
            <ul class="nav nav-treeview">
              <li class="nav-item nav-style">
                <router-link
                  to="/anggaran"
                  :class="NamePage == 'Daftar Anggaran' ? Active : notActive"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    height="24"
                    viewBox="0 -960 960 960"
                    width="24"
                    class="nav-icon"
                  >
                    <path
                      d="M549.999-451.539q-41.922 0-70.96-29.038-29.038-29.038-29.038-70.961 0-41.922 29.038-70.96 29.038-29.038 70.96-29.038 41.923 0 70.961 29.038 29.038 29.038 29.038 70.96 0 41.923-29.038 70.961-29.038 29.038-70.961 29.038ZM286.154-327.693q-29.826 0-51.067-21.24-21.24-21.24-21.24-51.067v-303.076q0-29.826 21.24-51.067 21.241-21.24 51.067-21.24h527.69q29.827 0 51.067 21.24 21.24 21.241 21.24 51.067V-400q0 29.827-21.24 51.067t-51.067 21.24h-527.69Zm59.999-59.998h407.693q0-29.923 21.24-51.115 21.24-21.193 51.067-21.193v-183.078q-29.923 0-51.115-21.24-21.192-21.241-21.192-51.067H346.153q0 29.923-21.24 51.115-21.241 21.192-51.067 21.192v183.078q29.923 0 51.115 21.241 21.192 21.24 21.192 51.067Zm420.768 199.997H146.156q-29.827 0-51.067-21.241-21.24-21.24-21.24-51.066v-396.151h59.998v396.151q0 4.615 3.847 8.462 3.846 3.846 8.462 3.846h620.765v59.999ZM286.154-387.691h-12.308V-715.384h12.308q-5 0-8.654 3.654t-3.654 8.654V-400q0 5 3.654 8.654 3.654 3.655 8.654 3.655Z"
                    />
                  </svg>
                  <p style="color: #1e1e1e">Anggaran</p>
                </router-link>
              </li>
              <li class="nav-item nav-style">
                <router-link
                  to="/topupanggaran"
                  :class="NamePage == 'Top Up Anggaran' ? Active : notActive"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    height="24"
                    viewBox="0 -960 960 960"
                    width="24"
                    class="nav-icon"
                  >
                    <path
                      d="M549.999-451.539q-41.922 0-70.96-29.038-29.038-29.038-29.038-70.961 0-41.922 29.038-70.96 29.038-29.038 70.96-29.038 41.923 0 70.961 29.038 29.038 29.038 29.038 70.96 0 41.923-29.038 70.961-29.038 29.038-70.961 29.038ZM286.154-327.693q-29.826 0-51.067-21.24-21.24-21.24-21.24-51.067v-303.076q0-29.826 21.24-51.067 21.241-21.24 51.067-21.24h527.69q29.827 0 51.067 21.24 21.24 21.241 21.24 51.067V-400q0 29.827-21.24 51.067t-51.067 21.24h-527.69Zm59.999-59.998h407.693q0-29.923 21.24-51.115 21.24-21.193 51.067-21.193v-183.078q-29.923 0-51.115-21.24-21.192-21.241-21.192-51.067H346.153q0 29.923-21.24 51.115-21.241 21.192-51.067 21.192v183.078q29.923 0 51.115 21.241 21.192 21.24 21.192 51.067Zm420.768 199.997H146.156q-29.827 0-51.067-21.241-21.24-21.24-21.24-51.066v-396.151h59.998v396.151q0 4.615 3.847 8.462 3.846 3.846 8.462 3.846h620.765v59.999ZM286.154-387.691h-12.308V-715.384h12.308q-5 0-8.654 3.654t-3.654 8.654V-400q0 5 3.654 8.654 3.654 3.655 8.654 3.655Z"
                    />
                  </svg>
                  <p style="color: #1e1e1e">Validasi Top Up Anggaran</p>
                </router-link>
              </li>
              <li class="nav-item nav-style">
                <router-link
                  to="/kegiatan"
                  :class="
                    NamePage == 'Daftar Proyeksi Kegiatan' ? Active : notActive
                  "
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    height="24"
                    viewBox="0 -960 960 960"
                    width="24"
                    class="nav-icon"
                  >
                    <path
                      d="M587.693-240q-38.538 0-65.423-26.884-26.884-26.885-26.884-65.423t26.884-65.423q26.885-26.884 65.423-26.884t65.423 26.884Q680-370.845 680-332.307t-26.884 65.423Q626.231-240 587.693-240ZM212.309-100.001q-30.308 0-51.308-21t-21-51.308v-535.382q0-30.308 21-51.308t51.308-21h55.385v-84.615h61.537v84.615h303.076v-84.615h59.999v84.615h55.385q30.308 0 51.308 21t21 51.308v535.382q0 30.308-21 51.308t-51.308 21H212.309Zm0-59.999h535.382q4.616 0 8.463-3.846 3.846-3.847 3.846-8.463v-375.382H200v375.382q0 4.616 3.846 8.463 3.847 3.846 8.463 3.846ZM200-607.69h560v-100.001q0-4.616-3.846-8.463-3.847-3.846-8.463-3.846H212.309q-4.616 0-8.463 3.846-3.846 3.847-3.846 8.463v100.001Zm0 0V-720v112.31Z"
                    />
                  </svg>
                  <p style="color: #1e1e1e">Validasi Kegiatan</p>
                </router-link>
              </li>
              <li class="nav-item nav-style">
                <router-link
                  to="/validasiPengajuanBiaya"
                  :class="
                    NamePage == 'Validasi Pengajuan Biaya' ? Active : notActive
                  "
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    height="24"
                    viewBox="0 -960 960 960"
                    width="24"
                    class="nav-icon"
                  >
                    <path
                      d="M587.693-240q-38.538 0-65.423-26.884-26.884-26.885-26.884-65.423t26.884-65.423q26.885-26.884 65.423-26.884t65.423 26.884Q680-370.845 680-332.307t-26.884 65.423Q626.231-240 587.693-240ZM212.309-100.001q-30.308 0-51.308-21t-21-51.308v-535.382q0-30.308 21-51.308t51.308-21h55.385v-84.615h61.537v84.615h303.076v-84.615h59.999v84.615h55.385q30.308 0 51.308 21t21 51.308v535.382q0 30.308-21 51.308t-51.308 21H212.309Zm0-59.999h535.382q4.616 0 8.463-3.846 3.846-3.847 3.846-8.463v-375.382H200v375.382q0 4.616 3.846 8.463 3.847 3.846 8.463 3.846ZM200-607.69h560v-100.001q0-4.616-3.846-8.463-3.847-3.846-8.463-3.846H212.309q-4.616 0-8.463 3.846-3.846 3.847-3.846 8.463v100.001Zm0 0V-720v112.31Z"
                    />
                  </svg>
                  <p style="color: #1e1e1e">Validasi Biaya</p>
                </router-link>
              </li>
              <li class="nav-item nav-style">
                <router-link
                  to="/validasiPengajuanKomitmen"
                  :class="
                    NamePage == 'Validasi Pengajuan Komitmen'
                      ? Active
                      : notActive
                  "
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    height="24"
                    viewBox="0 -960 960 960"
                    width="24"
                    class="nav-icon"
                  >
                    <path
                      d="M222-200 80-342l56-56 85 85 170-170 56 57-225 226Zm0-320L80-662l56-56 85 85 170-170 56 57-225 226Zm298 240v-80h360v80H520Zm0-320v-80h360v80H520Z"
                    />
                  </svg>
                  <p style="color: #1e1e1e">Validasi Komitmen</p>
                </router-link>
              </li>
              <!-- switch anggaran -->
              <li class="nav-item">
                <a href="#" class="nav-link">
                  <p class="white-all">
                    SWITCH ANGGARAN
                    <i class="right fas fa-angle-left"></i>
                  </p>
                </a>
                <ul class="nav nav-treeview">
                  <li class="nav-item nav-style">
                    <router-link
                      to="/switchSubMataAnggaran"
                      :class="
                        NamePage == 'Switch Sub Mata Anggaran'
                          ? Active
                          : notActive
                      "
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        height="24"
                        viewBox="0 -960 960 960"
                        width="24"
                        class="nav-icon"
                      >
                        <path
                          d="M320-160q-117 0-198.5-81.5T40-440q0-107 70.5-186.5T287-718l-63-66 56-56 160 160-160 160-56-57 59-59q-71 14-117 69t-46 127q0 83 58.5 141.5T320-240h120v80H320Zm200-360v-280h360v280H520Zm0 360v-280h360v280H520Zm80-80h200v-120H600v120Z"
                        />
                      </svg>
                      <p style="color: #1e1e1e">Validasi (Sub Mata Anggaran)</p>
                    </router-link>
                  </li>
                  <li class="nav-item nav-style">
                    <router-link
                      to="/switchDepartemen"
                      :class="
                        NamePage == 'Switch Anggaran Antar Departemen'
                          ? Active
                          : notActive
                      "
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        height="24"
                        viewBox="0 -960 960 960"
                        width="24"
                        class="nav-icon"
                      >
                        <path
                          d="M320-160q-117 0-198.5-81.5T40-440q0-107 70.5-186.5T287-718l-63-66 56-56 160 160-160 160-56-57 59-59q-71 14-117 69t-46 127q0 83 58.5 141.5T320-240h120v80H320Zm200-360v-280h360v280H520Zm0 360v-280h360v280H520Zm80-80h200v-120H600v120Z"
                        />
                      </svg>
                      <p style="color: #1e1e1e">Validasi (Antar Departemen)</p>
                    </router-link>
                  </li>
                </ul>
              </li>
              <li class="nav-item nav-style">
                <router-link
                  to="/realisasianggaran"
                  :class="NamePage == 'Realisasi Anggaran' ? Active : notActive"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    height="24"
                    viewBox="0 -960 960 960"
                    width="24"
                    class="nav-icon"
                  >
                    <path
                      d="M549.999-451.539q-41.922 0-70.96-29.038-29.038-29.038-29.038-70.961 0-41.922 29.038-70.96 29.038-29.038 70.96-29.038 41.923 0 70.961 29.038 29.038 29.038 29.038 70.96 0 41.923-29.038 70.961-29.038 29.038-70.961 29.038ZM286.154-327.693q-29.826 0-51.067-21.24-21.24-21.24-21.24-51.067v-303.076q0-29.826 21.24-51.067 21.241-21.24 51.067-21.24h527.69q29.827 0 51.067 21.24 21.24 21.241 21.24 51.067V-400q0 29.827-21.24 51.067t-51.067 21.24h-527.69Zm59.999-59.998h407.693q0-29.923 21.24-51.115 21.24-21.193 51.067-21.193v-183.078q-29.923 0-51.115-21.24-21.192-21.241-21.192-51.067H346.153q0 29.923-21.24 51.115-21.241 21.192-51.067 21.192v183.078q29.923 0 51.115 21.241 21.192 21.24 21.192 51.067Zm420.768 199.997H146.156q-29.827 0-51.067-21.241-21.24-21.24-21.24-51.066v-396.151h59.998v396.151q0 4.615 3.847 8.462 3.846 3.846 8.462 3.846h620.765v59.999ZM286.154-387.691h-12.308V-715.384h12.308q-5 0-8.654 3.654t-3.654 8.654V-400q0 5 3.654 8.654 3.654 3.655 8.654 3.655Z"
                    />
                  </svg>
                  <p style="color: #1e1e1e">Validasi Realisasi Anggaran</p>
                </router-link>
              </li>
            </ul>
          </li>

          <!-- Keuangan/Treasury (BUM) -->
          <li class="nav-item" v-show="namaAkses == 'BUM'">
            <a href="#" class="nav-link">
              <p class="white-all">
                TRANSAKSI
                <i class="right fas fa-angle-left"></i>
              </p>
            </a>
            <ul class="nav nav-treeview">
              <li class="nav-item nav-style">
                <router-link
                  to="/realisasianggaran"
                  :class="
                    NamePage == 'Validasi Realisasi Anggaran'
                      ? Active
                      : notActive
                  "
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    height="24"
                    viewBox="0 -960 960 960"
                    width="24"
                    class="nav-icon"
                  >
                    <path
                      d="M549.999-451.539q-41.922 0-70.96-29.038-29.038-29.038-29.038-70.961 0-41.922 29.038-70.96 29.038-29.038 70.96-29.038 41.923 0 70.961 29.038 29.038 29.038 29.038 70.96 0 41.923-29.038 70.961-29.038 29.038-70.961 29.038ZM286.154-327.693q-29.826 0-51.067-21.24-21.24-21.24-21.24-51.067v-303.076q0-29.826 21.24-51.067 21.241-21.24 51.067-21.24h527.69q29.827 0 51.067 21.24 21.24 21.241 21.24 51.067V-400q0 29.827-21.24 51.067t-51.067 21.24h-527.69Zm59.999-59.998h407.693q0-29.923 21.24-51.115 21.24-21.193 51.067-21.193v-183.078q-29.923 0-51.115-21.24-21.192-21.241-21.192-51.067H346.153q0 29.923-21.24 51.115-21.241 21.192-51.067 21.192v183.078q29.923 0 51.115 21.241 21.192 21.24 21.192 51.067Zm420.768 199.997H146.156q-29.827 0-51.067-21.241-21.24-21.24-21.24-51.066v-396.151h59.998v396.151q0 4.615 3.847 8.462 3.846 3.846 8.462 3.846h620.765v59.999ZM286.154-387.691h-12.308V-715.384h12.308q-5 0-8.654 3.654t-3.654 8.654V-400q0 5 3.654 8.654 3.654 3.655 8.654 3.655Z"
                    />
                  </svg>
                  <p style="color: #1e1e1e">Validasi Realisasi Anggaran</p>
                </router-link>
              </li>
              <li class="nav-item nav-style">
                <router-link
                  to="/entryrealisasianggaran"
                  :class="
                    NamePage == 'Entry Realisasi Anggaran' ? Active : notActive
                  "
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    height="24"
                    viewBox="0 -960 960 960"
                    width="24"
                    class="nav-icon"
                  >
                    <path
                      d="M160-160q-33 0-56.5-23.5T80-240v-120h80v120h640v-480H160v120H80v-120q0-33 23.5-56.5T160-800h640q33 0 56.5 23.5T880-720v480q0 33-23.5 56.5T800-160H160Zm300-140-56-58 83-82H80v-80h407l-83-82 56-58 180 180-180 180Z"
                    />
                  </svg>
                  <p style="color: #1e1e1e">Entry Realisasi</p>
                </router-link>
              </li>
            </ul>
          </li>

          <!-- Laporan Realisasi Super admin -->
          <li class="nav-item nav-style" v-show="namaAkses == 'Superadmin'">
            <router-link
              to="/laporanRealisasi"
              :class="NamePage == 'Laporan Realisasi' ? Active : notActive"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                height="24"
                viewBox="0 -960 960 960"
                width="24"
                class="nav-icon"
              >
                <path
                  d="M320-600q17 0 28.5-11.5T360-640q0-17-11.5-28.5T320-680q-17 0-28.5 11.5T280-640q0 17 11.5 28.5T320-600Zm0 160q17 0 28.5-11.5T360-480q0-17-11.5-28.5T320-520q-17 0-28.5 11.5T280-480q0 17 11.5 28.5T320-440Zm0 160q17 0 28.5-11.5T360-320q0-17-11.5-28.5T320-360q-17 0-28.5 11.5T280-320q0 17 11.5 28.5T320-280ZM200-120q-33 0-56.5-23.5T120-200v-560q0-33 23.5-56.5T200-840h440l200 200v440q0 33-23.5 56.5T760-120H200Zm0-80h560v-400H600v-160H200v560Zm0-560v160-160 560-560Z"
                />
              </svg>
              <p style="color: #1e1e1e">Laporan Realisasi</p>
            </router-link>
          </li>
          <!-- Laporan Realisasi Departemen Head -->

          <li
            class="nav-item nav-style"
            v-show="namaAkses == 'Departemen Head'"
          >
            <router-link
              to="/laporanRealisasi"
              :class="NamePage == 'Laporan Realisasi' ? Active : notActive"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                height="24"
                viewBox="0 -960 960 960"
                width="24"
                class="nav-icon"
              >
                <path
                  d="M320-600q17 0 28.5-11.5T360-640q0-17-11.5-28.5T320-680q-17 0-28.5 11.5T280-640q0 17 11.5 28.5T320-600Zm0 160q17 0 28.5-11.5T360-480q0-17-11.5-28.5T320-520q-17 0-28.5 11.5T280-480q0 17 11.5 28.5T320-440Zm0 160q17 0 28.5-11.5T360-320q0-17-11.5-28.5T320-360q-17 0-28.5 11.5T280-320q0 17 11.5 28.5T320-280ZM200-120q-33 0-56.5-23.5T120-200v-560q0-33 23.5-56.5T200-840h440l200 200v440q0 33-23.5 56.5T760-120H200Zm0-80h560v-400H600v-160H200v560Zm0-560v160-160 560-560Z"
                />
              </svg>
              <p style="color: #1e1e1e">Laporan Realisasi Departemen</p>
            </router-link>
          </li>

          <!-- Laporan Realisasi Departemen Head -->
          <li class="nav-item" v-show="namaAkses == 'Officer'">
            <a href="#" class="nav-link">
              <p class="white-all">
                LAPORAN
                <i class="right fas fa-angle-left"></i>
              </p>
            </a>
            <ul class="nav nav-treeview">
              <li class="nav-item nav-style" v-show="namaAkses == 'Officer'">
                <router-link
                  to="/laporanRealisasi"
                  :class="NamePage == 'Laporan Realisasi' ? Active : notActive"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    height="24"
                    viewBox="0 -960 960 960"
                    width="24"
                    class="nav-icon"
                  >
                    <path
                      d="M320-600q17 0 28.5-11.5T360-640q0-17-11.5-28.5T320-680q-17 0-28.5 11.5T280-640q0 17 11.5 28.5T320-600Zm0 160q17 0 28.5-11.5T360-480q0-17-11.5-28.5T320-520q-17 0-28.5 11.5T280-480q0 17 11.5 28.5T320-440Zm0 160q17 0 28.5-11.5T360-320q0-17-11.5-28.5T320-360q-17 0-28.5 11.5T280-320q0 17 11.5 28.5T320-280ZM200-120q-33 0-56.5-23.5T120-200v-560q0-33 23.5-56.5T200-840h440l200 200v440q0 33-23.5 56.5T760-120H200Zm0-80h560v-400H600v-160H200v560Zm0-560v160-160 560-560Z"
                    />
                  </svg>
                  <p style="color: #1e1e1e">Laporan Realisasi Departemen</p>
                </router-link>
              </li>
            </ul>
          </li>

          <!-- Summary Super admin -->
          <!-- <li class="nav-item nav-style" v-show="namaAkses == 'Superadmin'">
            <router-link
              to="/summary"
              :class="NamePage == 'Summary' ? Active : notActive"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                height="24"
                viewBox="0 -960 960 960"
                width="24"
                class="nav-icon"
              >
                <path
                  d="M320-280q17 0 28.5-11.5T360-320q0-17-11.5-28.5T320-360q-17 0-28.5 11.5T280-320q0 17 11.5 28.5T320-280Zm0-160q17 0 28.5-11.5T360-480q0-17-11.5-28.5T320-520q-17 0-28.5 11.5T280-480q0 17 11.5 28.5T320-440Zm0-160q17 0 28.5-11.5T360-640q0-17-11.5-28.5T320-680q-17 0-28.5 11.5T280-640q0 17 11.5 28.5T320-600Zm120 320h240v-80H440v80Zm0-160h240v-80H440v80Zm0-160h240v-80H440v80ZM200-120q-33 0-56.5-23.5T120-200v-560q0-33 23.5-56.5T200-840h560q33 0 56.5 23.5T840-760v560q0 33-23.5 56.5T760-120H200Zm0-80h560v-560H200v560Zm0-560v560-560Z"
                />
              </svg>
              <p style="color: #1e1e1e">Summary</p>
            </router-link>
          </li> -->

          <!-- Rekap Realisasi -->
          <li
            class="nav-item nav-style"
            v-show="namaAkses == 'General Manager'"
          >
            <router-link
              to="/rekapRealisasi"
              :class="NamePage == 'Rekap Realisasi' ? Active : notActive"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                height="24"
                viewBox="0 -960 960 960"
                width="24"
                class="nav-icon"
              >
                <path
                  d="M320-280q17 0 28.5-11.5T360-320q0-17-11.5-28.5T320-360q-17 0-28.5 11.5T280-320q0 17 11.5 28.5T320-280Zm0-160q17 0 28.5-11.5T360-480q0-17-11.5-28.5T320-520q-17 0-28.5 11.5T280-480q0 17 11.5 28.5T320-440Zm0-160q17 0 28.5-11.5T360-640q0-17-11.5-28.5T320-680q-17 0-28.5 11.5T280-640q0 17 11.5 28.5T320-600Zm120 320h240v-80H440v80Zm0-160h240v-80H440v80Zm0-160h240v-80H440v80ZM200-120q-33 0-56.5-23.5T120-200v-560q0-33 23.5-56.5T200-840h560q33 0 56.5 23.5T840-760v560q0 33-23.5 56.5T760-120H200Zm0-80h560v-560H200v560Zm0-560v560-560Z"
                />
              </svg>
              <p style="color: #1e1e1e">Rekap Realisasi</p>
            </router-link>
          </li>
        </ul>
      </nav>
      <!-- /.sidebar-menu -->
    </div>
    <!-- /.sidebar -->
  </aside>
</template>

<script>
export default {
  props: {
    NamePage: String,
  },
  data() {
    return {
      Active: "nav-link d-flex active",
      notActive: "nav-link d-flex ",
      userSession: JSON.parse(atob(localStorage.getItem("dataUser"))),
      namaAkses: localStorage.getItem("namaAkes"),
      currentDate: new Date()
        .toLocaleDateString("de-DE")
        .replace(".", "/")
        .replace(".", "/"),
    };
  },
};
</script>

<style>
.white-all {
  color: #1e1e1e;
}
.nav-style {
  border-radius: 0.25rem;
  box-shadow: inset 0 0 0 0 #fff;
  -webkit-transition: ease-out 0.6s;
  -moz-transition: ease-out 0.6s;
  transition: ease-out 0.6s;
}
.nav-style:hover {
  box-shadow: inset 400px 0 0 0 #4f8ab8;
}
.nav-style:hover p {
  color: #fff !important;
}
.nav-style:hover .nav-icon {
  fill: #fff !important;
}

.active {
  background-color: #ff3300 !important;
}
.active p {
  color: #fff !important;
}
.active .nav-icon {
  fill: #fff !important;
}
.main-sidebar {
  z-index: 21 !important;
}
#sidebar-overlay {
  z-index: 19 !important;
}
.div small {
  word-wrap: break-word;
  word-break: break-all;
}
.info {
  /* padding: 0 !important; */
  inline-size: 200px !important;
  height: auto !important;
  margin-bottom: 9px !important;
}

.user-panel,
.user-panel .info {
  white-space: unset !important;
  padding-bottom: 0 !important;
}
.user-panel {
  /* border: 2px solid #006699 !important;
  border-radius: 16px; */
  min-height: 125px !important;
  max-height: 150px !important;
}
</style>
