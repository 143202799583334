<template>
  <footer class="main-footer">
    BNI 2023 | E-Budgeting Powered by Janari
    <div class="float-right d-none d-sm-inline-block">
      <b>Version</b> 1.0
    </div>
  </footer>
</template>

<script>
export default {

}
</script>

<style>

</style>